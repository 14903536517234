import { AppThunk } from "store";
import * as pricelistApi from "api/pricelist.api";
import * as pricelistActions from "./pricelist.actions";

export const getContractPricelist =
  (selectedContractName: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(pricelistActions.contractPricelistRequestAC());
      const { data } = await pricelistApi.getContractPricelist(selectedContractName);
      dispatch(pricelistActions.contractPricelistRequestSuccessAC(data.products));
    } catch (error) {
      console.error(error, "CONTRACT PRICELIST REQUEST FAILURE");
      dispatch(pricelistActions.contractPricelistRequestFailureAC());
    }
  };
