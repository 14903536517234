import { UserPermissionObjectInfo } from "@skyportal/auth-web-client";

export interface ContractModel extends UserPermissionObjectInfo {}

export type SelectedContractName = string | null;

export interface ContractsStateSlice {
  readonly list: ContractModel[];
  readonly selectedContractName: SelectedContractName;
}

export const SET_CONTRACTS_LIST = "SET_CONTRACTS_LIST";
export type SetContractsListAction = { type: typeof SET_CONTRACTS_LIST; payload: ContractModel[] };
export type SetContractsListAC = (payload: ContractModel[]) => SetContractsListAction;

export const SELECTED_CONTRACT_NAME = "SELECTED_CONTRACT_NAME";
export type SelectContractIdAction = { type: typeof SELECTED_CONTRACT_NAME; payload: SelectedContractName };
export type SelectContractIdAC = (payload: SelectedContractName) => SelectContractIdAction;

export type ContractActions = SetContractsListAction | SelectContractIdAction;
