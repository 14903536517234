import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors } from "store/contracts";
import { pricelistSelectors, pricelistThunks } from "store/pricelist";

const useFetchContractPricelist = () => {
  const thunkDispatch = useThunkDispatch();
  const selectedContractName = useSelector(contractSelectors.getSelectedContractName);

  useEffect(() => {
    if (selectedContractName) {
      thunkDispatch(pricelistThunks.getContractPricelist(selectedContractName));
    }
  }, [thunkDispatch, selectedContractName]);

  return useSelector(pricelistSelectors.getSlice);
};

export default useFetchContractPricelist;
