import * as types from "./pricelist.types";

export const contractPricelistRequestAC: types.ContractPricelistRequestAC = () => ({
  type: types.CONTRACT_PRICELIST_REQUEST,
});

export const contractPricelistRequestSuccessAC: types.ContractPricelistRequestSuccessAC = (payload) => ({
  type: types.CONTRACT_PRICELIST_REQUEST_SUCCESS,
  payload,
});

export const contractPricelistRequestFailureAC: types.ContractPricelistRequestFailureAC = () => ({
  type: types.CONTRACT_PRICELIST_REQUEST_FAILURE,
});

export const setPricelistSortFieldAC: types.SetPricelistSortFieldAC = (payload) => ({
  type: types.SET_PRICELIST_SORT_FIELD,
  payload,
});

export const setPricelistPerPageAC: types.SetPricelistPerPageAC = (payload) => ({
  type: types.SET_PRICELIST_PER_PAGE,
  payload,
});

export const setPricelistCurrentPageAC: types.SetPricelistCurrentPageAC = (payload) => ({
  type: types.SET_PRICELIST_CURRENT_PAGE,
  payload,
});
