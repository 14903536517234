import { RootState } from "../index";
import { ContractsStateSlice } from "./contracts.types";
import { createSelector } from "reselect";
import { ContractTypesWithPricelist } from "store/app/app.thunk.actions";

export const getContractsSlice = (state: RootState): ContractsStateSlice => state.contracts;

export const getContractList = (state: RootState): ContractsStateSlice["list"] => getContractsSlice(state).list;

export const getSelectedContractName = (state: RootState): ContractsStateSlice["selectedContractName"] =>
  getContractsSlice(state).selectedContractName;

const serviceNameByContractType = {
  IA: "Private Cloud",
};

export const getContractDropdownOptions = createSelector(getContractList, (contracts) =>
  contracts.map((item) => ({
    label: `${item.parentName} - ${serviceNameByContractType[item.entityType as ContractTypesWithPricelist]} (${
      item.entityType
    }${item.name})`,
    value: `${item.entityType}${item.name}`,
  }))
);
