import { SET_CONTRACTS_LIST, SELECTED_CONTRACT_NAME, SetContractsListAC, SelectContractIdAC } from "./contracts.types";

export const setContractsList: SetContractsListAC = (payload) => ({
  type: SET_CONTRACTS_LIST,
  payload,
});

export const selectedContractNameAC: SelectContractIdAC = (payload) => ({
  type: SELECTED_CONTRACT_NAME,
  payload,
});
