import { ContractActions, ContractsStateSlice } from "./contracts.types";

const initState: ContractsStateSlice = {
  list: [],
  selectedContractName: null,
};

export default function contractReducer(
  state: ContractsStateSlice = initState,
  action: ContractActions
): ContractsStateSlice {
  switch (action.type) {
    case "SET_CONTRACTS_LIST":
      return {
        ...state,
        list: action.payload,
        selectedContractName: `${action.payload[0].entityType}${action.payload[0].name}`,
      };
    case "SELECTED_CONTRACT_NAME":
      return {
        ...state,
        selectedContractName: action.payload,
      };
    default:
      return state;
  }
}
