import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "pages/HomePage";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/pricelist" exact component={HomePage} />
    <Route path="*">
      <Redirect to="/pricelist" />
    </Route>
  </Switch>
);

export default AppRouter;
