import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as pricelist } from "./pricelist";

const rootReducer = combineReducers({
  app,
  contracts,
  pricelist,
});

export default rootReducer;
