import { RootState } from "../index";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { PricelistStateSlice } from "./pricelist.types";
import { ProductModel } from "types/pricelistApi.types";

export const getSlice = (state: RootState) => state.pricelist;
export const getPricelist = (state: RootState) => state.pricelist.list;

const makeProductIdSortNormalizer = (item: ProductModel) => item.productId;
const makeDescriptionSortNormalizer = (item: ProductModel) => item.description.toLowerCase();
const makeUnitPriceSortNormalizer = (item: ProductModel) => Number(item.unitPrice);

const pricelistComposer = ({ list, sortField, sortOrder, currentPage, perPage }: PricelistStateSlice) => {
  let _list = [...list];

  const sortHandler: any = {
    productId: makeUniversalSort(makeProductIdSortNormalizer),
    description: makeUniversalSort(makeDescriptionSortNormalizer),
    unitPrice: makeUniversalSort(makeUnitPriceSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getPricelistTable = (state: RootState) => {
  const pricelist = getSlice(state);
  return pricelistComposer(pricelist);
};
