import { RequestStatus } from "types/common.types";
import { ProductModel } from "types/pricelistApi.types";

export interface PricelistStateSlice {
  readonly list: ProductModel[];
  readonly requestStatus: RequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
}

export type SortFieldType = keyof ProductModel | "";
export type SortOrderType = "asc" | "desc";

export const CONTRACT_PRICELIST_REQUEST = "CONTRACT_PRICELIST_REQUEST";
export type ContractPricelistRequestAction = { type: typeof CONTRACT_PRICELIST_REQUEST };
export type ContractPricelistRequestAC = () => ContractPricelistRequestAction;

export const CONTRACT_PRICELIST_REQUEST_SUCCESS = "CONTRACT_PRICELIST_REQUEST_SUCCESS";
export type ContractPricelistRequestSuccessAction = {
  type: typeof CONTRACT_PRICELIST_REQUEST_SUCCESS;
  payload: ProductModel[];
};
export type ContractPricelistRequestSuccessAC = (payload: ProductModel[]) => ContractPricelistRequestSuccessAction;

export const CONTRACT_PRICELIST_REQUEST_FAILURE = "CONTRACT_PRICELIST_REQUEST_FAILURE";
export type ContractPricelistRequestFailureAction = { type: typeof CONTRACT_PRICELIST_REQUEST_FAILURE };
export type ContractPricelistRequestFailureAC = () => ContractPricelistRequestFailureAction;

export const SET_PRICELIST_SORT_FIELD = "SET_PRICELIST_SORT_FIELD";
export type SetPricelistSortFieldAction = {
  type: typeof SET_PRICELIST_SORT_FIELD;
  payload: SortFieldType;
};
export type SetPricelistSortFieldAC = (payload: SortFieldType) => SetPricelistSortFieldAction;

export const SET_PRICELIST_PER_PAGE = "SET_PRICELIST_PER_PAGE";
export type SetPricelistPerPageAction = {
  type: typeof SET_PRICELIST_PER_PAGE;
  payload: number;
};
export type SetPricelistPerPageAC = (payload: number) => SetPricelistPerPageAction;

export const SET_PRICELIST_CURRENT_PAGE = "SET_PRICELIST_CURRENT_PAGE";
export type SetPricelistCurrentPageAction = {
  type: typeof SET_PRICELIST_CURRENT_PAGE;
  payload: number;
};
export type SetPricelistCurrentPageAC = (payload: number) => SetPricelistCurrentPageAction;

export type PricelistActions =
  | ContractPricelistRequestAction
  | ContractPricelistRequestSuccessAction
  | ContractPricelistRequestFailureAction
  | SetPricelistSortFieldAction
  | SetPricelistPerPageAction
  | SetPricelistCurrentPageAction;
