import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import PricelistTable from "components/PricelistTable";

const HomePage: React.FC = () => {
  const { t } = useTranslation("pricelistPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <PricelistTable />
    </PageLayout>
  );
};

export default HomePage;
