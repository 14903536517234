import { RequestStatus } from "types/common.types";
import {
  CONTRACT_PRICELIST_REQUEST,
  CONTRACT_PRICELIST_REQUEST_FAILURE,
  CONTRACT_PRICELIST_REQUEST_SUCCESS,
  PricelistActions,
  PricelistStateSlice,
  SET_PRICELIST_CURRENT_PAGE,
  SET_PRICELIST_PER_PAGE,
  SET_PRICELIST_SORT_FIELD,
} from "./pricelist.types";

export const initState: PricelistStateSlice = {
  list: [],
  requestStatus: RequestStatus.UNCALLED,
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
};

export default function pricelistReducer(
  state: PricelistStateSlice = initState,
  action: PricelistActions
): PricelistStateSlice {
  switch (action.type) {
    case CONTRACT_PRICELIST_REQUEST: {
      return {
        ...state,
        requestStatus: RequestStatus.PENDING,
      };
    }
    case CONTRACT_PRICELIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        requestStatus: RequestStatus.SUCCESS,
      };
    }
    case CONTRACT_PRICELIST_REQUEST_FAILURE: {
      return {
        ...initState,
        requestStatus: RequestStatus.FAILURE,
      };
    }
    case SET_PRICELIST_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case SET_PRICELIST_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case SET_PRICELIST_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
