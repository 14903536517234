import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Spinner, Table } from "@skyportal/ui-kit";
import useFetchContractPricelist from "hooks/useFetchContractPricelist";
import { useTranslation } from "react-i18next";
import { RequestStatus } from "types/common.types";
import { ProductModel } from "types/pricelistApi.types";
import {
  setPricelistCurrentPageAC,
  setPricelistPerPageAC,
  setPricelistSortFieldAC,
} from "store/pricelist/pricelist.actions";
import PaginationBox from "containers/PaginationBox";
import { pricelistSelectors } from "store/pricelist";

import styles from "./styles.module.css";

const PricelistTable = () => {
  const { t } = useTranslation("pricelistTable");
  const dispatch = useDispatch();
  const { sortField, sortOrder, perPage, currentPage, requestStatus } = useFetchContractPricelist();
  const { list, pageCount } = useSelector(pricelistSelectors.getPricelistTable);

  const columnsConfig = useMemo(
    () => [
      { key: "productId", dataIndex: "productId", title: t("productId"), width: "120px" },
      { key: "description", dataIndex: "description", title: t("description") },
      {
        key: "unitPrice",
        dataIndex: "unitPrice",
        title: t("unitPrice"),
        width: "120px",
        render: (unitPrice: ProductModel["unitPrice"], item: ProductModel) =>
          `${Number(unitPrice).toFixed(2)} ${item.currency}`,
      },
    ],
    [t]
  );

  const handleSortFieldChange = useCallback((field) => dispatch(setPricelistSortFieldAC(field)), [dispatch]);
  const handlePerPageChange = useCallback((value) => dispatch(setPricelistPerPageAC(value)), [dispatch]);
  const handlePageChange = useCallback((value) => dispatch(setPricelistCurrentPageAC(value)), [dispatch]);

  return (
    <Card className={styles.card} data-testid="pricelistTable">
      <Spinner className={styles.spinner} show={requestStatus === RequestStatus.PENDING} />
      <div className={styles.pricelistTableBlock}>
        <Table
          dataSource={list}
          columns={columnsConfig}
          sortField={sortField}
          sortOrder={sortOrder}
          onSortFieldChange={handleSortFieldChange}
        />
      </div>
      <PaginationBox
        perPage={perPage}
        currentPage={currentPage}
        pageCount={pageCount}
        onPerPageChange={handlePerPageChange}
        onChangePage={handlePageChange}
        className={styles.paginationBox}
      />
    </Card>
  );
};

export default PricelistTable;
