import { UserPermissionObjectInfo } from "@skyportal/auth-web-client";
import { AppThunk } from "store";
import { authClient } from "api/clients";
import * as appActions from "./app.actions";
import * as contractActions from "../contracts/contracts.actions";
import config from "config";
import { sort } from "utils/sort";

export type ContractTypesWithPricelist = "IA";
export const contractTypesWithPricelist: ContractTypesWithPricelist[] = ["IA"];

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();
    const contractsWithPricelistFeature = userProfile.permissions.entity
      .filter((contract) => contractTypesWithPricelist.includes(contract.entityType as ContractTypesWithPricelist))
      .map((contract) => {
        const parentObject = userProfile.permissions[contract.parentType as string]?.find(
          (item) => item.id === contract.parentId
        );
        const parentName = parentObject?.name ?? "Noname";
        return { ...contract, parentName } as UserPermissionObjectInfo;
      });

    if (!contractsWithPricelistFeature.length) {
      window.location.href = config.baseUrl;
    }

    sort(contractsWithPricelistFeature, "parentName", "entityType", "name");

    dispatch(appActions.setUserProfile(userProfile));
    dispatch(contractActions.setContractsList(contractsWithPricelistFeature));
  } catch (error) {
    console.error(error, "USER INFO REQUEST");
  }
};
